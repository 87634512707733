<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <main class="hero-body--wrapper">
        <TheHeader />
        <div class="is-flex">
          <div class="container">
            <router-view />
          </div>
        </div>
      </main>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Home',
  components: {
    TheHeader: () => import('@/components/TheHeader.vue')
  }
}
</script>

<style scoped lang="scss">
.hero.is-fullheight .hero-body {
  padding: 0;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  max-width: 100vw;
}
.hero-body--wrapper {
  overflow-x: hidden;
  position: relative;
  background: var(--main-background-color);
  .is-flex {
    height: calc(100vh - 64px);
    margin-top: 64px;
  }
}
main {
  width: 100%;
  height: 100%;
}
</style>
